import { defineStore } from 'pinia'

interface State {
	version: number,
	/**
	 * The order that is currently active / being used
	 */
	activeOrderId?: string | null,
}

export const useOrderStore = defineStore('order', {
  state: (): State => ({
	  activeOrderId: null,
	  version: 0, // Bump this when doing local changes, and use in key to force re-render
	}),
	actions: {
		incrementVersion() {
			this.version++
		},
		setActiveOrderId(id: string | null) {
			if (id && id.length === 37) { // FO - remove leading "O"/"C" from order id
				this.activeOrderId = id.slice(1)
			} else {
				this.activeOrderId = id
			}
		}
	}

})
